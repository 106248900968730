export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      action: 'read',
      resource: 'public'
    }
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/views/error/Error401.vue'),
    meta: {
      action: 'read',
      resource: 'public'
    }
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/error/Error403.vue'),
    meta: {
      action: 'read',
      resource: 'public'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]
