export default [
  {
    path: '/demo',
    name: 'demo',
    component: () => import('@/layouts/main/Demo.vue'),
    meta: {
      action: 'read',
      resource: 'public'
    },
    children: [
      {
        path: '/demo/study-room',
        name: 'demo.study-room',
        component: () => import('@/views/Demo/StudyRoom/Index.vue'),
        meta: {
          action: 'read',
          resource: 'public'
        }
      },
      {
        path: '/demo/study-room/study-result',
        name: 'demo.study-room.study-result',
        component: () => import('@/views/Demo/StudyRoom/StudyResult/Index'),
        meta: {
          action: 'can',
          resource: 'public'
        }
      },
      {
        path: '/demo/study-room/:talkScheduleId',
        name: 'demo.study-room.content',
        component: () => import('@/views/Demo/StudyRoom/Content/Index'),
        meta: {
          action: 'can',
          resource: 'public'
        }
      },
      {
        path: '/demo/study-room/:talkScheduleId/:studyRoomId/listen-rec',
        name: 'demo.study-room.listen-rec.content',
        component: () => import('@/views/Demo/StudyRoom/Content/Type/ListenRec'),
        meta: {
          action: 'can',
          resource: 'public'
        }
      },
      {
        path: '/demo/study-room/:talkScheduleId/:studyRoomId/video',
        name: 'demo.study-room.video.content',
        component: () => import('@/views/Demo/StudyRoom/Content/Type/Video'),
        meta: {
          action: 'can',
          resource: 'public'
        }
      },
      {
        path: '/demo/study-room/:talkScheduleId/:studyRoomId/translation',
        name: 'demo.study-room.translation.content',
        component: () => import('@/views/Demo/StudyRoom/Content/Type/Translation'),
        meta: {
          action: 'can',
          resource: 'public'
        }
      },
      {
        path: '/demo/study-room/:talkScheduleId/:studyRoomId/quiz',
        name: 'demo.study-room.quiz.content',
        component: () => import('@/views/Demo/StudyRoom/Content/Type/Quiz'),
        meta: {
          action: 'can',
          resource: 'public'
        }
      }
    ]
  }
]
