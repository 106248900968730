import Vue from 'vue'
import Vuex from 'vuex'
import { isUserLoggedIn, getUserData } from '@/auth/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: isUserLoggedIn(),
    userData: getUserData()
  },
  getters: {
    isLoggedIn (state) {
      return state.isLoggedIn
    },
    userData (state) {
      return state.userData
    }
  },
  mutations: {
    toggleIsLoggedIn (state, payload) {
      state.isLoggedIn = payload
    },
    setUserData (state, payload) {
      state.userData = payload
    }
  },
  actions: {
    TOGGLE_IS_LOGGED_IN (context, payload) {
      context.commit('toggleIsLoggedIn', payload)
    },
    SET_USER_DATA (context, payload) {
      context.commit('setUserData', payload)
    }
  },
  modules: {
  }
})
