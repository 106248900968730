import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

/**
 * Route modules
 */
import user from './routes/user'
import misc from './routes/misc'
import auth from './routes/auth'
import demo from './routes/demo'

Vue.use(VueRouter)

const routes = [
  ...user,
  ...auth,
  ...misc,
  ...demo
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  var skipLogin = false
  if (to.name.indexOf('.') !== '-1') {
    console.log(to.name.substr(0, to.name.indexOf('.')))
    if (to.name.substr(0, to.name.indexOf('.')) === 'demo') {
      skipLogin = true
    }
  }

  console.log(skipLogin)

  if (!skipLogin) {
    if (!canNavigate(to)) {
      // Redirect to login if not logged inz
      if (!isLoggedIn) return next({ name: 'login' })

      // If logged in => not authorized
      return next({ name: 'error-401' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()

      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
  }

  return next()
})

export default router
