import useJwt from '@/libs/auth/jwt/useJwt'
import axios from '@/libs/axios'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/auth/user/login',
  refreshEndpoint: '/auth/user/refresh',
  logoutEndpoint: '/user/logout',
  registerEndpoint: '/auth/user/register',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
})
export default jwt
