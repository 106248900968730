export default [
  {
    path: '/',
    component: () => import('@/layouts/main/User.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home/Index'),
        meta: {
          action: 'read',
          resource: 'public'
        }
      },
      {
        path: '/program',
        name: 'program',
        component: () => import('@/views/Program/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/program/level',
        name: 'program.level',
        component: () => import('@/views/Program/Level/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/program/level/:levelId/class-per-week',
        name: 'program.level.class-per-week',
        component: () => import('@/views/Program/Level/ClassPerWeek/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/program/level/:levelId/class-per-week/:classPerWeekId/set-video-class',
        name: 'program.level.class-per-week.set-video-class',
        component: () => import('@/views/Program/Level/ClassPerWeek/SetVideoClass/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/program/time',
        name: 'program.time',
        component: () => import('@/views/Program/Level/ClassPerWeek/SetVideoClass/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/program/point',
        name: 'program.point',
        component: () => import('@/views/Program/Point'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/my-page',
        name: 'my-page',
        component: () => import('@/views/MyPage/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/my-page/point',
        name: 'my-page.point',
        component: () => import('@/views/MyPage/Point/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/my-page/notification',
        name: 'notification',
        component: () => import('@/views/MyPage/Notification/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/my-page/point02',
        name: 'my-page.point02',
        component: () => import('@/views/MyPage/Point02'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/my-page/my-info',
        name: 'my-page.my-info',
        component: () => import('@/views/MyPage/MyInfo/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room',
        name: 'study-room',
        component: () => import('@/views/StudyRoom/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/study-result',
        name: 'study-room.study-result',
        component: () => import('@/views/StudyRoom/StudyResult/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      // {
      //   path: '/study-room/:talkScheduleId/:quizId/quiz',
      //   name: 'study-room.quiz',
      //   component: () => import('@/views/StudyRoom/Content/Tests/Quiz/Index'),
      //   meta: {
      //     action: 'can',
      //     resource: 'enroll'
      //   }
      // },
      {
        path: '/study-room/:talkScheduleId',
        name: 'study-room.content',
        component: () => import('@/views/StudyRoom/Content/Index'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/translation',
        name: 'study-room.translation.content',
        component: () => import('@/views/StudyRoom/Content/Type/Translation'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/listen-rec',
        name: 'study-room.listen-rec.content',
        component: () => import('@/views/StudyRoom/Content/Type/ListenRec'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/video',
        name: 'study-room.video.content',
        component: () => import('@/views/StudyRoom/Content/Type/Video'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/audio',
        name: 'study-room.audio.content',
        component: () => import('@/views/StudyRoom/Content/Type/Audio'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/listening-test',
        name: 'study-room.listening-test.content',
        component: () => import('@/views/StudyRoom/Content/Type/ListeningTest'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/comprehension-test',
        name: 'study-room.comprehension-test.content',
        component: () => import('@/views/StudyRoom/Content/Type/ComprehensionTest'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/word-test',
        name: 'study-room.word-test.content',
        component: () => import('@/views/StudyRoom/Content/Type/WordTest'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/quiz',
        name: 'study-room.quiz.content',
        component: () => import('@/views/StudyRoom/Content/Type/Quiz'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/vocabulary-check',
        name: 'study-room.vocabulary-check.content',
        component: () => import('@/views/StudyRoom/Content/Type/VocabularyCheck'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      },
      {
        path: '/study-room/:talkScheduleId/:studyRoomId/writing',
        name: 'study-room.writing.content',
        component: () => import('@/views/StudyRoom/Content/Type/Writing'),
        meta: {
          action: 'can',
          resource: 'enroll'
        }
      }
    ]
  }
]
