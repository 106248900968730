export default [
  {
    path: '/',
    component: () => import('@/layouts/main/User.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
          action: 'read',
          resource: 'Auth'
        }
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue'),
        meta: {
          action: 'read',
          resource: 'Auth'
        }
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
          action: 'read',
          resource: 'Auth'
        }
      },
      {
        path: '/change-password/:accessToken/:tokenId',
        name: 'change-password',
        component: () => import('@/views/ChangePassword.vue'),
        meta: {
          action: 'read',
          resource: 'Auth'
        }
      }
    ]
  }
]
